export default [
  {
    header: 'Settings',
    icon: 'SettingsIcon',
    route: 'page-layout-layout-empty',    
    children: [
      {
        title: 'Hardware setup (Stock)',
        route: 'setting-hardware-type', 
        icon: 'PrinterIcon',
        permissionCode:'ST001',
      },
      {
        title: 'Hardware setup (Warranty)',
        route: 'setting-hardware-warranty-type', 
        icon: 'PrinterIcon',
        permissionCode:'ST002',
      },
      {
        title: 'Customer setup (Stock)',
        route: 'setting-customer-owner', 
        icon: 'UsersIcon',
        permissionCode:'ST003',
      },
      {
        title: 'Customer setup (Warranty)',
        route: 'setting-customer-warranty-subcompany', 
        icon: 'UsersIcon',
        permissionCode:'ST003-1',
      },
      {
        title: 'Roles and permission',
        route: 'setting-role', 
        icon: 'BoxIcon',
        permissionCode:'ST004',
      },
      {
        title: 'Manage user',
        route: 'setting-user', 
        icon: 'UserIcon',
        permissionCode:'ST005',
      },
      {
        title: 'ตั้งค่าระยะทางลูกค้า',
        route: 'setting-distance-customer', 
        icon: 'MapPinIcon',
        permissionCode:'ST006',
      },
      {
        title: 'ตั้งค่าระยะทางพนักงาน',
        route: 'setting-distance-employee', 
        icon: 'MapIcon',
        permissionCode:'ST007',
      },
      {
        title: 'ตั้งค่า Email',
        route: 'setting-email', 
        icon: 'MailIcon',
        permissionCode:'ST008',
      }
    ]            
  },
]


