<template>
  <layout-horizontal>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
    
  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import HealthService from "@/services/health"
import store from "@/store/index";

export default {
  components: {
    LayoutHorizontal,
    AppCustomizer,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },

  beforeCreate(){
    store.dispatch('user/init')  
  },

  created(){
    setInterval(() => {
      this.checkHealth()
    }, 1000*60);
  },

  methods: {
    checkHealth() {
      HealthService.Check()
    },
    
  },
}
</script>
