export default [
  {
    header: 'Tickets',
    icon: 'GridIcon',
    children: [
      {
        title: 'Inprogress',
        route: 'ticket-inprogress',  
        icon: 'ClockIcon',
        permissionCode:'TK001',
      },
      {
        title: 'Closed',
        route: 'ticket-closed',  
        icon: 'CheckCircleIcon',
        permissionCode:'TK003',
      },
      {
        title: 'Cancel',
        route: 'ticket-cancel',  
        icon: 'XOctagonIcon',
        permissionCode:'TK005',
      },
    ]  
  },
]


