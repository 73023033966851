<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <bookmarks /> -->

      <h2 class="brand-text mb-0 text-primary" style="font-weight: bold">
        <!-- <b-img :src="appLogoImage" alt="logo" width="30" /> -->
        {{ appName }}
      </h2>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <b-nav-item @click="changeMode">
    <feather-icon
      size="21"
      :icon="`${skin!=='dark' ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
      
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BImg, BNavbarNav } from "bootstrap-vue";

import { $themeConfig } from "@themeConfig";

import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";
import SearchBar from "./components/SearchBar.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import { BNavItem } from 'bootstrap-vue'

import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BNavItem
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    const { skin } = useAppConfig()

  

    const changeMode = ()=>{
      if(skin.value=='dark'){
        skin.value = 'light'
      }else{
        skin.value = 'dark'
      }
    }

    return {
      appName,
      appLogoImage,
      skin ,changeMode   
      };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
