export default [
  {
    header: 'Stock',
    icon: 'GridIcon',
       
    children: [
      {
        title: 'Vserv',
        route: 'stock-vserv', 
        icon: 'PackageIcon',
        permissionCode:'STK001',
      },
      {
        title: 'Repair',
        route: 'stock-repair', 
        icon: 'PrinterIcon',
        permissionCode:'STK002',
      },
      {
        title: 'Repair history',
        route: 'stock-repair-history', 
        icon: 'PrinterIcon',
        permissionCode:'STK006',
      },
      {
        title: 'Onhand',
        route: 'stock-onhand', 
        icon: 'UsersIcon',
        permissionCode:'STK003',
      },
      {
        title: 'Warranty',
        route: 'stock-warranty', 
        icon: 'ClockIcon',
        permissionCode:'STK004',
      },
      {
        title: 'Movement',
        route: 'stock-movement', 
        icon: 'ActivityIcon',
        permissionCode:'STK005',
      },
    ]      
  },
]


