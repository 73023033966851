export default [
  {
    header: 'Reports',
    icon: 'GridIcon',
    route: 'page-layout-layout-empty',    
    children: [
      {
        title: 'Ticket report',
        route: 'report-ticket', 
        icon: 'ClipboardIcon',
        permissionCode:'RP001',
      },
      {
        title: 'Accounting report',
        route: 'report-accounting', 
        icon: 'FileTextIcon',
        permissionCode:'RP004',
      }
    ]            
  },
]


